import React, { Component } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ServiceHeader from '../../components/ServiceHeader';
import FAQ from '../../components/FAQService1';
import ServicePage21WhyChoose from '../../components/ServicePage21WhyChoose';
import ServicePageForm from '../../components/ServicePageForm';

import Banner from '../../components/Banner'

import { Link } from "react-router-dom";

import NewReferences from '../../components/NewReferences';

import './style.scss';

// import Feature from '../../components/Feature'

// import './App.scss';

class BlogPostPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: null,
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    this.loadPost();
  }

  loadPost() {
    this.setState({ loading: true });
    fetch(`https://nsbackend.campaigns.fi/blog_posts/public/post/${this.props.match.params.slug}`)
      .then(response => response.json())
      .then(data => {
        this.setState({
          post: data,
          loading: false,
          error: null,
        });
      })
      .catch(error => {
        this.setState({
          post: null,
          loading: false,
          error: error,
        });
      });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="page homepage">
          <Banner> 
              <h2>Blogit</h2>
          </Banner>

          <Container>
            <Row>
              <Col>
                <p>Virhe: {this.state.error.message}</p>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    if (this.state.loading || !this.state.post) {
      return (
        <div className="page blogpostpage">
          <Banner> 
              <h2>Blogit</h2>
          </Banner>

          <Container>
            <Row>
              <Col>
                <p>Ladataan...</p>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    
    return (
      <div className="page blogpostpage">       
        <Banner image={this.state.post && this.state.post.header_image ? `https://nsbackend.campaigns.fi/${this.state.post.header_image}` : null}>
            <h2>Blogi</h2>
        </Banner>
        <Container className="intro p-t-50 p-b-50" id="content">
            <Row>
                <Col sm={{ span: 8, offset: 2}}>
                  <div dangerouslySetInnerHTML={{__html: this.state.post.top_content}}></div>
                  {this.state.post.filler_image && <img src={`https://nsbackend.campaigns.fi/${this.state.post.filler_image}`} alt={this.state.post.title} />}
                  <div dangerouslySetInnerHTML={{__html: this.state.post.bottom_content}}></div>
                </Col>
            </Row>
        </Container>
      </div>
    );
  }
}

export default BlogPostPage;
