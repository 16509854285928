import React, { Component } from 'react';
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import HomePage from './containers/HomePage'
import OfferPage from './containers/OfferPage'
import ServicesPage from './containers/ServicesPage'
import ServicesPage2_1 from './containers/ServicesPage2_1'
import ServicesPage2_1_1 from './containers/ServicesPage2_1_1';
import ServicesPage2_1_2 from './containers/ServicesPage2_1_2';
import ServicesPage2_1_3 from './containers/ServicesPage2_1_3';
import ServicesPage2_1_4 from './containers/ServicesPage2_1_4';
import ServicesPage2_1_5 from './containers/ServicesPage2_1_5';
import ServicesPage2_1_6 from './containers/ServicesPage2_1_6';
import ServicesPage2_2 from './containers/ServicesPage2_2';
import ServicesPage2_2_1 from './containers/ServicesPage2_2_1';
import ServicesPage2_2_2 from './containers/ServicesPage2_2_2';
import ServicesPage2_2_3 from './containers/ServicesPage2_2_3';
import ServicesPage2_2_4 from './containers/ServicesPage2_2_4';
import ServicesPage2_2_5 from './containers/ServicesPage2_2_5';
import ServicesPage2_2_6 from './containers/ServicesPage2_2_6';
import ServicesPage2_2_7 from './containers/ServicesPage2_2_7';
import ServicesPage2_3 from './containers/ServicesPage2_3';
import ServicesPage2_3_1 from './containers/ServicesPage2_3_1';
import ServicesPage2_3_2 from './containers/ServicesPage2_3_2';
import ServicesPage2_3_3 from './containers/ServicesPage2_3_3';
import ServicesPage2_3_4 from './containers/ServicesPage2_3_4';
import ServicesPage2_3_5 from './containers/ServicesPage2_3_5';
import ServicesPage2_3_6 from './containers/ServicesPage2_3_6';
import ServicesPage2_3_7 from './containers/ServicesPage2_3_7';
import ServicesPage2_4 from './containers/ServicesPage2_4';
import ContactPage from './containers/ContactPage'
import ReferencesPage from './containers/ReferencesPage'
import EmployeePage from './containers/EmployeePage'

import OpenApplicationPage from './containers/OpenApplicationPage'
import JobApplicationPage from './containers/JobApplicationPage'
import OpenJobsPage from './containers/OpenJobsPage'
import RecruitmentListPage from './containers/RecruitmentListPage'

import GDPRPage from './containers/GDPRPage'

import BlogIndexPage from './containers/BlogIndexPage'
import BlogPostPage from './containers/BlogPostPage'

import NotFoundPage from './containers/NotFoundPage'
import  Header from './components/Header';
import  Footer from './components/Footer';
import './App.scss';
import fb from './facebook.jpg'
import {Helmet} from 'react-helmet'

import ScrollToTop from './ScrollToTop';

class App extends Component {

  // constructor(props){
  //   super(props);

  // }


  render() {
    return (
      <div className="App" id="App">
      <Helmet>
            <title>Nordic Security Alliance</title>
            <meta name="description" content="Tarjoamme turvallisuusalan palveluita yrityksille, yksityishenkilöille, yhteisöille sekä julkiselle
                                sektorille. Palvelemme asiakkaitamme 32 paikkakunnalla ympäri Suomen reilun 1000 henkilön
                                voimin." />
            <meta property="og:title" content="Nordic Security Alliance" />
            <meta property="og:site_name" content="Nordic Security Alliance"/>
            <meta property="og:url" content="http://www.nordicsec.fi" />
            <meta property="og:description" content="Tarjoamme turvallisuusalan palveluita yrityksille, yksityishenkilöille, yhteisöille sekä julkiselle
                        sektorille. Palvelemme asiakkaitamme 32 paikkakunnalla ympäri Suomen reilun 1000 henkilön
                        voimin." />
            <meta property="og:image" content={fb} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />

        </Helmet>
      <Router>
      <ScrollToTop />
      <Header />
      <Switch >
        <Route exact path="/" component={HomePage}/> {/* 1.0 */}
        <Route  path="/tajouspyynto" component={OfferPage}/>
        <Route  path="/palvelut" component={ServicesPage}/>

        <Route  path="/jarjestyksenvalvonta/ravintolaturvallisuus" component={ServicesPage2_1_1}/>
        <Route  path="/jarjestyksenvalvonta/tapahtumaturvallisuus" component={ServicesPage2_1_2}/>
        <Route  path="/jarjestyksenvalvonta/leirintaalue" component={ServicesPage2_1_3}/>
        <Route  path="/jarjestyksenvalvonta/matkustaja-alus" component={ServicesPage2_1_4}/>
        <Route  path="/jarjestyksenvalvonta/joukkoliikennejaliikenneasemat" component={ServicesPage2_1_5}/>
        <Route  path="/jarjestyksenvalvonta/kauppakeskus" component={ServicesPage2_1_6}/>
        <Route  path="/jarjestyksenvalvonta" component={ServicesPage2_1}/>

        <Route  path="/vartiointi/paikallisvartiointi" component={ServicesPage2_2_1}/>
        <Route  path="/vartiointi/tyomaajateollisuusvartiointi" component={ServicesPage2_2_2}/>
        <Route  path="/vartiointi/myymalavartiointi" component={ServicesPage2_2_3}/>
        <Route  path="/vartiointi/lastensuojelu" component={ServicesPage2_2_4}/>
        <Route  path="/vartiointi/aulapalvelut" component={ServicesPage2_2_5}/>
        <Route  path="/vartiointi/henkilosuojaus" component={ServicesPage2_2_6}/>
        <Route  path="/vartiointi/etsiva-ja-rikostenpaljastamistoiminta" component={ServicesPage2_2_7}/>
        <Route  path="/vartiointi" component={ServicesPage2_2}/>

        <Route  path="/asiantuntijapalvelut/tapahtuman-turvallisuus-ja-pelastussuunnitelma" component={ServicesPage2_3_1}/>
        <Route  path="/asiantuntijapalvelut/ensiapusuunnitelma" component={ServicesPage2_3_2}/>
        <Route  path="/asiantuntijapalvelut/liikenteenohjaussuunnitelma" component={ServicesPage2_3_3}/>
        <Route  path="/asiantuntijapalvelut/kiinteiston-pelastussuunnitelma" component={ServicesPage2_3_4}/>
        <Route  path="/asiantuntijapalvelut/turvallisuusvalvoja" component={ServicesPage2_3_6}/>
        <Route  path="/asiantuntijapalvelut/turvallisuuspaallikko" component={ServicesPage2_3_5}/>
        <Route  path="/asiantuntijapalvelut/hse-valvoja" component={ServicesPage2_3_7}/>
        <Route  path="/asiantuntijapalvelut" component={ServicesPage2_3}/>

        <Route  path="/muutpalvelut/vaatesailytys"><Redirect to="/muutpalvelut#vaatesailytys"/></Route>
        <Route  path="/muutpalvelut/radiopuhelin"><Redirect to="/muutpalvelut#radiopuhelin"/></Route>
        <Route  path="/muutpalvelut/ensiapu"><Redirect to="/muutpalvelut#ensiapu"/></Route>
        <Route  path="/muutpalvelut/defibrillaattori"><Redirect to="/muutpalvelut#defibrillaattori"/></Route>
        <Route  path="/muutpalvelut/liikenteenohjaus"><Redirect to="/muutpalvelut#liikenteenohjaus"/></Route>
        <Route  path="/muutpalvelut/alkometrit"><Redirect to="/muutpalvelut#alkometrit"/></Route>
        <Route  path="/muutpalvelut/turvatekniikka"><Redirect to="/muutpalvelut#turvatekniikka"/></Route>
        <Route  path="/muutpalvelut" component={ServicesPage2_4} />

        <Route  path="/referenssit" component={ReferencesPage}/>
        <Route  path="/yhteystiedot" component={ContactPage}/>

        <Route  path="/tyonhakijalle/avoimettyopaikat" component={OpenJobsPage}/>
        <Route  path="/tyonhakijalle/liityrekrylistalle" component={RecruitmentListPage}/>
        <Route  path="/tyonhakijalle/avoinhakemus" component={OpenApplicationPage}/>
        <Route  path="/tyonhakijalle/tyo/:id" component={JobApplicationPage}/>
        <Route  path="/tyonhakijalle" component={EmployeePage}/>

        <Route  path="/tietosuojaseloste" component={GDPRPage}/>

        <Route  path="/blogi/:slug" component={BlogPostPage}/>
        <Route  path="/blogi" component={BlogIndexPage}/>

        <Route  component={NotFoundPage}/>
      </Switch>

      <Footer />
      </Router>
      
      </div>
    );
  }
}

export default App;
