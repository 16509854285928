import React, { Component } from 'react';
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
// import { HashLink as Link } from 'react-router-hash-link';
import './style.scss';

class BlogPostCard extends Component {
  render() {
    return (
        <div className="blogPostCard" key={this.props.id}>
          <a href={`/blogi/${this.props.post.slug}`}></a>
          <div className="image" style={{backgroundImage: `url(https://nsbackend.campaigns.fi/${this.props.post.filler_image})`}}></div>
          <div className="tags"><strong>Tagit:</strong> {this.props.post.tags ? this.props.post.tags : 'Uncategorized'}</div>
          <div className="date">{this.props.post.published}</div>
          <h4>{this.props.post.title}</h4>
          <p>{this.props.post.summary}</p>
          <div className="blogCTA">Lue artikkeli</div>
        </div>
    );
  }
}

export default BlogPostCard;
