import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import placeholderImage from './banner.jpg';
import './style.scss';

class Banner extends Component {
  render() {
    console.log("IMAGE: ")
    console.log(this.props.image);

    return (
      <Container fluid className="banner">


          <div className="banner-img" style={{backgroundImage: "url(" + (this.props.image ? this.props.image : placeholderImage) +")"}}></div>

      <div className="banner-content">
          {this.props.children}
          
      </div>
                
    </Container>
    );
  }
}

export default Banner;
